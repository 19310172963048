import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import "./About.css";

const About = () => {
  return (
    <div className="about-2">
      <Breadcrumbs names={{ about: "About Us" }} />

      <div className="aboutrow01">
        <div className="maxwidcontainer">
          <div className="aboutinner1">
            <div className="aboutimgbox">
              <h2>FOUNDER’S </h2>
              <h3>MESSAGE</h3>
              <div className="founderimg"><img src="/img/founder.png" alt="founder" /></div>
            </div>
            <div className="abcontent">
              <p>
                At CRAFT CI, our mission is to empower businesses for Continuous
                Improvement by supporting Hotels and Integrated Resorts in
                aligning each operational activity with their Brand Identity.
              </p>

              <p>
                With a combined 40+ years in hospitality operations, we
                understand the complexities and challenges a multi-cultural,
                service-driven operation has to endure.
              </p>

              <p>
                Through our dedicated brand pillars; we impart knowledge,
                establish alignment and provide practical technology, to
                establish and sustain a culture of continuous improvement. This
                ensures a growth-driven model for teams to always stay aligned
                with their core purpose.
              </p>

              <p>
                Whether you are a pre-opening or established hotel, Continuous
                Improvement is essential for sustained success and brand
                clarity.
              </p>

              <p>
                If this resonates with you, please send us a note through our
                connect form with any questions.
              </p>

              <p>
                We are keen to learn about your goals and make an impact on your
                team’s culture.
              </p>
              <p>Sincerely, <br></br>
                <span className="autname">Faheem</span></p>
              <img src="/img/sign.png" alt="sign" />
            </div>
          </div>
        </div>
      </div>
      <div className="secondrow">
        <div className="secinner maxwidcontainer">
          <h2>CRAFT CI has served teams that represent over 52,000 
          hotel rooms worldwide. </h2>
          <div className="aboutmapimg">
            <img src="/img/about-map.png" alt="about-map" />
          </div>
          <div className="threeboxes01">
            <div className="threeboxinner01"><p>A dedicated team in our <span>tenth year of business</span></p></div>
            <div className="threeboxinner01"><p>Combined <span>43 years</span> of Hotel, Spa & Wellness operating experience (grass roots to senior leadership)</p></div>
            <div className="threeboxinner01"><p>Combined <span>38 years</span> of software 
            development & technology experience</p></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import "./Contact.css";
import Breadcrumbs from "../components/Breadcrumbs";

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      phone: phone,
      message: message,
    };

    // Initialize EmailJS
    emailjs.init('ELvtNdSgekhX2xgPL');

    // Send email using EmailJS
    emailjs.send('service_9yspjgb', 'template_rdz1hzg', templateParams)
      .then((response) => {
        console.log('Email sent successfully!');
        setStatusMessage('Email sent successfully!');
        resetForm();
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setStatusMessage('Error sending email. Please try again.');
      });
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
  };

  return (
    <div className='contactpage page-id05'>
      <Breadcrumbs names={{ contact: "Contact" }} />
      <div className="contactrow01">
        <div className="maxwidcontainer">
          <h2>Get in Touch</h2>
          <form onSubmit={sendEmail}>
            <div className="form-group1">
              <div className="half-width">
                <label>
                  Name:
                  <input
                    type="text"
                    value={name}
                    placeholder="Enter your name"
                    name="user_name"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </label>
              </div>
              <div className="half-width">
                <label>
                  Email:
                  <input
                    type="email"
                    name='user_email'
                    value={email}
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>
            <div className="form-group2">
              <div className="full-width">
                <label>
                  Phone:
                  <input
                    type="tel"
                    name='user_phone'
                    value={phone}
                    placeholder="Enter your phone number"
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </label>
              </div>
              <div className="full-width">
                <label>
                  Message:
                  <textarea
                    value={message}
                    name='user_msg'
                    placeholder="Enter your message"
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>
            <button type="submit">Send</button>
            {statusMessage && (
              <p className={`statusMessage ${statusMessage.includes('Error') ? 'error' : 'success'}`}>
                {statusMessage}
              </p>
            )}
          </form>
        </div>
      </div>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230.72559753125574!2d114.20658576805346!3d22.292775199999987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040111dadb0317%3A0xee62a671614a3113!2sKodak%20House%20II!5e0!3m2!1sen!2sin!4v1725508202924!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps Embed"
        />
      </div>
    </div>
  );
};

export default Contact;

import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // EmailJS library
import './Footer.css';

const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    emailjs.sendForm('service_9yspjgb', 'template_78hw48m', e.target, 'ELvtNdSgekhX2xgPL')
      .then((result) => {
        setSuccess(true);
        setError(null);
        setEmail(''); // Clear email field
      }, (error) => {
        setSuccess(false);
        setError(error.text);
      });
  };

  return (
    <div>
      <div className="neweletterformbox">
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="user_email"
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">
            GO
          </button>
        </form>
      </div>
      {success && <p>Email sent successfully!</p>}
      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default EmailForm;
import React from 'react'
import "./Products.css";
import Breadcrumbs from "../components/Breadcrumbs";
import { useNavigate } from "react-router-dom";
const Products = () => {
    //btn links
      //downloadbtnlinks
  const navigate = useNavigate(); // Initialize useNavigate hook

  const qtxbtn = () => {
    navigate("#"); // Navigate to the desired route
  };
  const qtxdemobtn = () => {
    navigate("#"); // Navigate to the desired route
  };
  const spabtn = () => {
    navigate("#"); // Navigate to the desired route
  };
  const spademobtn = () => {
    navigate("#"); // Navigate to the desired route
  };
  return (
    <div className='products page-id-04'>
        <Breadcrumbs names={{ customer: "Customer" }} />
        <div className="productfirstrow">
            <div className="maxwidcontainer">
                <img src="/img/qtx.png" alt="qtx" />
                <h2>Aligning Hotel Operations with Brand Identity</h2>
                <p>The most effective tool for improving service quality in any business. Consolidating 
real-time tracking of operational excellence activities and guest satisfaction Qtx is 
actionable and relevant for any service business.</p>
<div className="casedwnbtn">
                  <button onClick={qtxbtn} className="button">
                  QTX Suite - Introduction Deck
                  </button>
                </div>
                <div className="qtxdemo">
                    <img src="/img/qtx-demo.png" alt="qtx-demo" />
                </div>
                <div className="casedwnbtn">
                <button onClick={qtxdemobtn} className="button">
                Request a Demo
                  </button>
                  </div>
            </div>
        </div>
        <div className="productfirstrow productsecrow" >
            <div className="maxwidcontainer">
                <img src="/img/spa.png" alt="spa.png" />
                <h2>Equipping Spa’s to Optimize Growth</h2>
                <p>Real-time KPI tracking for spa operations with Real-time industry analytics.</p>
<div className="casedwnbtn">
                  <button onClick={spabtn} className="button">
                  learn more
                  </button>
                </div>
                <div className="qtxdemo">
                    <img src="/img/spa-demo.png" alt="spa-demo" />
                </div>
                <div className="casedwnbtn">
                <button onClick={spademobtn} className="button">
                Request a Demo
                  </button>
                  </div>
            </div>
        </div>
      
    </div>
  )
}

export default Products

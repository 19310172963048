import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Gallery from "../components/Gallery";
import "./Customer.css";
import { useNavigate } from "react-router-dom";
const Customer = () => {
  //downloadbtnlinks
  const navigate = useNavigate(); // Initialize useNavigate hook

  const Hyattlink = () => {
    navigate("#"); // Navigate to the desired route
  };
  const accorlink = () => {
    navigate("#");
  };
  const parisian = () => {
    navigate("#");
  };
  const galaxy = () => {
    navigate("#");
  };
  const raffles = () => {
    navigate("#");
  };
  const solaire = () => {
    navigate("#");
  };
  //gallery images url
  const images = [
    "/clients/1.png",
    "/clients/2.png",
    "/clients/3.png",
    "/clients/4.png",
    "/clients/5.png",
    "/clients/6.png",
    "/clients/7.png",
    "/clients/8.png",
    "/clients/9.png",
    "/clients/10.png",
    "/clients/11.png",
    "/clients/12.png",
    "/clients/13.png",
    "/clients/14.png",
    "/clients/15.png",
    "/clients/16.png",
    "/clients/17.png",
    "/clients/18.png",
    "/clients/19.png",
    "/clients/20.png",
    "/clients/21.png",
  ];

  return (
    <div className="customer page-id-03">
      <Breadcrumbs names={{ customer: "Customer" }} />
      <div className="ourclientrow">
        <div className="maxwidcontainer">
          <h1>Our Clients</h1>
          <p>The global and local teams that we serve</p>

          <div className="gallery1">
            <Gallery images={images} />
          </div>
        </div>{" "}
      </div>
      <div className="casestudies">
        <div className="maxwidcontainer">
          <h2>Case Studies</h2>
          <p>Learn and share in our customers’ journey!</p>
          <div className="casrrowodd caserowflex">
            <div className="caseimg">
              <img src="/img/hyatt1.png" alt="hyatt" />
            </div>
            <div className="casecontentbox">
              <div className="rmaxwid">
                <img src="/img/hyatt-logo.png" alt="hyatt-logo" />
                <h3>
                  Hyatt optimizes key operational team members with QTX Perform
                </h3>
                <div className="casedwnbtn">
                  <button onClick={Hyattlink} className="button">
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="casrroweven caserowflex">
            <div className="casecontentbox">
              <div className="maxwid">
                <img src="/img/accor.png" alt="accor" />
                <h3>
                  Accor Hotels builds a global solution to connect 200+ spa
                  businesses
                </h3>
                <div className="casedwnbtn">
                  <button onClick={accorlink} className="button">
                    Download
                  </button>
                </div>
              </div>
            </div>
            <div className="caseimg">
              <img src="/img/accor-hotel.png" alt="accor-hotel" />
            </div>
          </div>
          <div className="casrrowodd caserowflex">
            <div className="caseimg">
              <img src="/img/parisian-hotel.png" alt="parisian-hotel" />
            </div>
            <div className="casecontentbox">
              <div className="rmaxwid">
                <img src="/img/parisian.png" alt="parisian" />
                <h3>
                  A 3000 room integrated casino resort simulates over 50,000
                  interactions in 4 days
                </h3>
                <div className="casedwnbtn">
                  <button onClick={parisian} className="button">
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="casrroweven caserowflex">
            <div className="casecontentbox">
              <div className="maxwid">
                <img src="/img/Galaxy-Macau.png" alt="Galaxy-Macau" />
                <h3>
                  Galaxy Macau uses QTX to turn a broken Executive on Duty role
                  into a success
                </h3>
                <div className="casedwnbtn">
                  <button onClick={galaxy} className="button">
                    Download
                  </button>
                </div>
              </div>
            </div>
            <div className="caseimg">
              <img src="/img/galaxy-hotel.png" alt="galaxy-hotel" />
            </div>
          </div>

          <div className="casrrowodd caserowflex">
            <div className="caseimg">
              <img src="/img/raffles-hotel.png" alt="raffles-hotel" />
            </div>
            <div className="casecontentbox">
              <div className="rmaxwid">
                <img src="/img/raffles.png" alt="raffles" />
                <h3>
                  Raffles Singapore prepares for an iconic restoration with QTX
                  Launch
                </h3>
                <div className="casedwnbtn">
                  <button onClick={raffles} className="button">
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="casrroweven caserowflex">
            <div className="casecontentbox">
              <div className="maxwid">
                <img src="/img/solaire.png" alt="solaire" />
                <h3>
                  Simulation initiative yields over one million data points to
                  prepare a high profile Integrated Resort for a highly
                  successful opening.
                </h3>
                <div className="casedwnbtn">
                  <button onClick={solaire} className="button">
                    Download
                  </button>
                </div>
              </div>
            </div>
            <div className="caseimg">
              <img src="/img/solaire-hotel.png" alt="solaire-hotel" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;

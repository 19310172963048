import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Ensure Link is imported
import './Footer.css';
import EmailForm from './EmailForm';

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  const currentYear = new Date().getFullYear();

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <div className="footer">
        <div className="footerinnerrow maxwidcontainer">
          <div className="footerinnercolumn">
            <div className="footerinnercolumn1">
              <Link to="/"><img src="/logo.png" alt="Footer Logo" /></Link>
              <div className="socialicon">
                <a href='https://www.facebook.com/' target="_blank" rel="noreferrer">
                  <i className="fa fa-facebook-f"></i>
                </a>
                <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href='https://www.youtube.com/' target="_blank" rel="noreferrer">
                  <i className="fa fa-youtube-play"></i>
                </a>
              </div>
            </div>
            <div className="footerinnercolumn2 fm">
              <h2>Navigation</h2>
              <ul className="foomenu">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/products">Products</Link></li>
                <li><Link to="/customer">Customer</Link></li>
                <li><Link to="/team">Team</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
              </ul>
            </div>
            <div className="footerinnercolumn3 fm">
              <h2>Quick Links</h2>
              <ul className="foomenu">
                <li><Link to="#">Blog</Link></li>
                <li><Link to="#">Newsletter</Link></li>
                <li><Link to="#">Workshop</Link></li>
                <li><Link to="#">Train the Trainer</Link></li>
                <li><Link to="#">Request a Demo</Link></li>
              </ul>
            </div>
            <div className="footerinnercolumn4 fm">
              <h2>Newsletter</h2>
              <p>Engage and sign up for updates!</p>
              <EmailForm />
            </div>
          </div>
        </div>
        <div className="foobtm">
          <p>© {currentYear} All Rights Reserved.</p>
        </div>
        {/* Scroll to Top Button */}
        {isVisible && (
          <button className="scroll-to-top" onClick={scrollToTop}>
            ↑
          </button>
        )}
      </div>
    </div>
  );
};

export default Footer;

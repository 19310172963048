import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import './Team.css';
const Team = () => {
  return (
    <div className="teampage page-id-06">
      <Breadcrumbs />
      <div className="trackrecord">
        <div className="trackrecordinner maxwidcontainer">
          <h2>ADVISORY BOARD</h2>
          <div className="trackcontainer iconcontainer">
            <div className="trackcolumn iconcolumn">
              <div className="trackninner">
                <img src="/img/Jacob.png" alt="Jacob" className="image" />
                <div className="trackcontent">
                  <h3>Jacob Samuel</h3>
                  <p>Managing Director / Ares Asia</p>
                </div>
              </div>
              <div className="trackninner">
                <img src="/img/Pablo.png" alt="Pablo" className="image" />
                <div className="trackcontent">
                  <h3>Dr. Pablo Morales</h3>
                  <p>
                    {" "}
                    Discipleship Pastor / ECB Asia Doctor of Leadership & Global
                    Perspectives / George Fox University
                  </p>
                </div>
              </div>
              <div className="trackninner">
                <img src="/img/Michael.png" alt="Michael" className="image" />
                <div className="trackcontent">
                  <h3>Michael Cluze</h3>
                  <p>President / Eatigo COO / FunNow Group</p>
                </div>
              </div>
              <div className="trackninner">
                <img src="/img/Chris.png" alt="Chris" className="image" />
                <div className="trackcontent">
                  <h3>Chris Chan Ebrahim</h3>
                  <p>
                    Founder / Exbox Asia Children Ministry Head / ECB Strategic
                    Marketing Expert
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;

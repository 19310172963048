import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Breadcrumbs.css'; // Add your CSS file for styling

const Breadcrumbs = ({ names = {} }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const pathnames = pathname.split('/').filter(x => x);

  if (pathnames.length === 0) return null; // Return null for the home page

  return (
    <nav className="breadcrumbs">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        {pathnames.map((name, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1; // Check if it's the last item
          const displayName = names[name] || name.charAt(0).toUpperCase() + name.slice(1);

          return (
            <li key={to}>
              {isLast ? (
                <span>{displayName}</span> // Non-clickable
              ) : (
                <Link to={to}>
                  {displayName}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;

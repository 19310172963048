import React from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";

const Home = () => {

  const navigate = useNavigate(); // Initialize useNavigate hook

const handleClick = () => {
  navigate("/about"); // Navigate to the desired route
};
const csbtn = () => {
  navigate("/customer"); // Navigate to the desired route
};
const prodbtn = () => {
  navigate("/products"); // Navigate to the desired route
};

  return (
    <>
      <div className="main home"  id="main">
        <div className="homebannersection">
          <div className="bannerinner">
            <div className="bannercontent">
              <h1>Empowering</h1>
              <h2>Continuous Improvement</h2>
              <p>
                Enabling impact with businesses by aligning operations with
                brand identity to establish and nurture cultures of Continuous
                Improvement.
              </p>
              <button className="btn" onClick={handleClick}>
                Explore More
              </button>
            </div>
          </div>
        </div>

<div className="svgic">
<svg width="100%" height="100%" id="svg" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><path d="M 0,400 L 0,150 C 160.66666666666669,187.46666666666667 321.33333333333337,224.93333333333334 495,216 C 668.6666666666666,207.06666666666666 855.3333333333333,151.73333333333332 1015,133 C 1174.6666666666667,114.26666666666668 1307.3333333333335,132.13333333333333 1440,150 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="#8ed1fc" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
</div>

        <div className="missionsection">
          <div className="missioninner">
            <div className="missioncontent">
              <h3>Our Mission</h3>
              <h2>Empowering Businesses for Continuous Improvement</h2>
            </div>
            <div className="iconcontainer">
              <div className="iconcolumn">
                <div className="iconinner">
                  <img src="/img/1.png" alt="icon1" className="image" />
                  <div className="content">
                    <h2>Knowledge</h2>
                    <p>Empowering the people in business for Impact</p>
                  </div>
                </div>
                <div className="iconinner">
                  <img src="/img/2.png" alt="icon2" className="image" />
                  <div className="content">
                    <h2>Alignment</h2>
                    <p>
                      Aligning operations with brand identity to nurture
                      continuous improvement
                    </p>
                  </div>
                </div>
                <div className="iconinner">
                  <img src="/img/3.png" alt="icon3" className="image" />
                  <div className="content">
                    <h2>Technology</h2>
                    <p>
                      Implementing highly effective applications to enable teams
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="saveic">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#192151" fill-opacity="1" d="M0,64L60,58.7C120,53,240,43,360,48C480,53,600,75,720,96C840,117,960,139,1080,133.3C1200,128,1320,96,1380,80L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
</div>
        <div className="trackrecord">
          <div className="trackrecordinner maxwidcontainer">
            <h2>Success Stories</h2>
            <div className="trackcontainer iconcontainer">
              <div className="trackcolumn iconcolumn">
                <div className="trackninner">
                  <div className="trimg">
                  <img src="/img/hyatt.png" alt="hyatt" className="image" />
                  </div>
                  
                  <div className="trackcontent">
                    <p>
                      Hyatt optimizes key operational team members with QTX
                      Perform
                    </p>
                  </div>
                  <div className="buttonsec">
                    <button class="btn btn1 mtop"  onClick={csbtn}>Read more</button>
                  </div>
                </div>
                <div className="trackninner">
                <div className="trimg">
                  <img src="/img/accor.png" alt="accor" className="image" />
                  </div>
                  <div className="trackcontent">
                    <p>
                      Accor Hotels builds a global solution to connect 200+ spa
                      businesses
                    </p>
                  </div>
                  <div className="buttonsec">
                    <button class="btn btn1 mtop"  onClick={csbtn}>Read more</button>
                  </div>
                </div>
                <div className="trackninner">
                <div className="trimg">
                  <img src="/img/parisian.png" alt="parisian" className="image" />
                  </div>
                  <div className="trackcontent">
                    <p>
                      A 3000 room integrated casino resort simulates over 50,000
                      interactions in 4 days
                    </p>
                  </div>
                  <div className="buttonsec">
                    <button class="btn btn1 mtop"  onClick={csbtn}>Read more</button>
                  </div>
                </div>
                <div className="trackninner">
                <div className="trimg">
                  <img src="/img/Galaxy-Macau.png" alt="Galaxy-Macau" className="image" />
                  </div>
                  <div className="trackcontent">
                    <p>
                    Galaxy Macau uses QTX to turn a broken Executive on Duty role into a success
                    </p>
                  </div>
                  <div className="buttonsec">
                    <button class="btn btn1 mtop"  onClick={csbtn}>Read more</button>
                  </div>
                </div>
                <div className="trackninner">
                <div className="trimg">
                  <img src="/img/raffles.png" alt="raffles" className="image" />
                  </div>
                  <div className="trackcontent">
                    <p>
                    Raffles Singapore prepares for an iconic restoration with QTX Launch
                    </p>
                  </div>
                  <div className="buttonsec">
                    <button class="btn btn1 mtop"  onClick={csbtn}>Read more</button>
                  </div>
                </div>
                <div className="trackninner">
                <div className="trimg">
                  <img src="/img/solaire.png" alt="solaire" className="image" />
                  </div>
                  <div className="trackcontent">
                    <p>
                    Simulation initiative yields over one million data points to prepare a high profile Integrated Resort for 
                    a highly successful opening
                    </p>
                  </div>
                  <div className="buttonsec">
                    <button class="btn btn1 mtop"  onClick={csbtn}>Read more</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottobtn">
              <button className="btn btn1" onClick={csbtn}>
                SEE ALL CASE STUDIES
              </button>
            </div>
          </div>
        </div>
        <div className="saveic bgblck">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#192151" fill-opacity="1" d="M0,64L60,58.7C120,53,240,43,360,48C480,53,600,75,720,96C840,117,960,139,1080,133.3C1200,128,1320,96,1380,80L1440,64L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
        </div>
        <div className="productsrow">
          <div className="productsinnerrow maxwidcontainer">
            <div className="proinner">
              <div className="leftpro">
                <h2>OUR PRODUCTS</h2>
                <p>Technology for hotels, by hoteliers</p>
              </div>
              <div className="rightpro">
                <div className="proinnerrow">
                  <div className="innerbox01">
                    <img src="/img/qtx.png" alt="qtx" />
                    <h3>Aligning Hotel Operations with Brand Identity</h3>
                    <p>
                      QTX creates a culture of continuous improvement where hotels can experience complete alignment with their brand competencies.
                    </p>
                    <button className="btn btn2" onClick={prodbtn}>LEARN MORE</button>
                  </div>
                  <div className="innerbox01">
                    <img src="/img/spa.png" alt="spa" />
                    <h3>Equipping Spa’s to Optimize Growth</h3>
                    <p>With pre-opening and established hotels, QTX ensures direct alignment between each operating activity and a hotel’s Brand Identity. 
                    </p>
                    <button className="btn btn2" onClick={prodbtn}>LEARN MORE</button>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="svgic tests" bis_skin_checked="1"><svg width="100%" height="100%" id="svg" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><path d="M 0,400 L 0,150 C 160.66666666666669,187.46666666666667 321.33333333333337,224.93333333333334 495,216 C 668.6666666666666,207.06666666666666 855.3333333333333,151.73333333333332 1015,133 C 1174.6666666666667,114.26666666666668 1307.3333333333335,132.13333333333333 1440,150 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="#8ed1fc" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg></div>

        <div className="testimonailrow">
          <div className="testimonailinnerrow maxwidcontainer">
            <h2>TESTIMONIALS</h2>
            <div className="testimonialinnerrow">
            <div className="testimonialimgbox">
              <img src="/img/testimonial.png" alt="testimonial" />
            </div>
              <div className="testimonialbox">
                <div className="testibox textbox1">
                  <div className="textboxcon">
                    <img src="/img/quote.png" alt="quote" />
                    <p>QTX creates a culture of continuous improvement where hotel can experience complete alignment with their brand competencies.</p>
                    <p className="authoname">SARAH LAHAL</p>
                  </div>
                  <div className="authoimg">
                    <img src="/img/sarah.png" alt="sarah" />
                  </div>
                </div>
                <div className="testibox textbox2">
                  <div className="textboxcon">
                    <img src="/img/quote.png" alt="quote" />
                    <p>QTX creates a culture of continuous improvement where hotel can experience complete alignment with their brand competencies.</p>
                    <p className="authoname">REYAN DSOUZA</p>
                  </div>
                  <div className="authoimg">
                    <img src="/img/reyan.png" alt="reyan" />
                  </div>
                </div>
                <div className="testibox textbox2">
                  <div className="textboxcon">
                    <img src="/img/quote.png" alt="quote" />
                    <p>QTX creates a culture of continuous improvement where hotel can experience complete alignment with their brand competencies.</p>
                    <p className="authoname">MARIA KEML</p>
                  </div>
                  <div className="authoimg">
                    <img src="/img/maria.png" alt="maria" />
                  </div>
                </div>
                  {/* <button className="btn btn1 mtop" onClick={handleClick}>SEE ALL TESTIMONIALS</button> */}
 
              </div>
            </div>
          </div>
        </div>
        <div className="homebottomrow">
          <div className="homebottominner maxwidcontainer">
            <div className="fourboxes01">
              <div className="inbox">
                <img src="/img/asia-pacific.png" alt="asia-pacific" />
                <h3>Asia Pacific Spa & Wellness Awards 2024</h3>
              </div>
              <div className="inbox">
              <img src="/img/winner-spa.png" alt="winner-spa" />
                <h3>Best Technology Equipment Supplier</h3>
              </div>
              <div className="inbox">
              <img src="/img/business.png" alt="business" />
                <h3>Best Enterprise Solution Hospitality</h3>
              </div>
              <div className="inbox">
              <img src="/img/winner-tx.png" alt="winner-tx" />
                <h3>HKB Technology Excellence Awards </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
